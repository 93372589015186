<template>
  <v-container class="px-0">
    <v-card elevation="0">
      <v-card-text>
        <v-container>
          <validation-observer ref="observer">
            <form @submit.prevent="login">
              <v-row align="center" justify="center">
                <v-col cols="auto" class="pb-12">
                  <v-img
                    width="194"
                    :src="require('@/assets/images/sk-logomark.png')"
                  />
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="メールアドレス"
                    rules="required|email"
                  >
                    <div class="form-header mb-2">メールアドレス</div>
                    <input-email
                      v-model="email"
                      placeholder="メールアドレス"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    name="パスワード"
                    rules="required"
                  >
                    <div class="form-header mb-2">パスワード</div>
                    <input-password
                      v-model="password"
                      placeholder="パスワード"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="pt-16">
                  <v-btn
                    color="#F2C94C"
                    class="submit-btn"
                    block
                    type="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    {{ $t("title_login") }}
                  </v-btn>
                </v-col>

                <v-col cols="12" class="text-center pt-5">
                  <router-link to="/forgot-password">
                    {{ $t("forgot_password") }}
                  </router-link>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- <v-row class="mt-4 text-center terms-policy-container">
      <v-col cols="12" class="py-1">
        <router-link to="/privacy-policy">
          {{ $t("privacy_policy") }}
        </router-link>
      </v-col>
      <v-col cols="12" class="py-1">
        <router-link to="/terms-conditions">
          {{ $t("terms_of_service") }}
        </router-link>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import InputEmail from "@/components/ui/InputEmail";
import InputPassword from "@/components/ui/InputPassword";
import { mapGetters } from "vuex";

export default {
  name: "LoginPage",
  components: { InputPassword, InputEmail },
  data() {
    return {
      email: "",
      password: ""
    };
  },
  computed: {
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    login() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        let email = this.email;
        let password = this.password;
        this.$store
          .dispatch("AUTH_REQUEST", { email, password })
          .then(response => {
            if (response.status === 200) {
              this.$router.push("/");
            }
          })
          .catch(err => console.log(err));
      });
    }
  }
};
</script>

<style scoped>
.terms-policy-container {
  font-size: 11px !important;
}
.form-header {
  color: #555555 !important;
}
.submit-btn {
  font-size: 16px !important;
  color: #333 !important;
  font-weight: 700;
  height: 50px !important;
}
</style>

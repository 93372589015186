<template>
  <LoginPage />
</template>

<script>
import LoginPage from "@/components/layout/auth/LoginPage";
export default {
  name: "Login",
  components: { LoginPage }
};
</script>

<style scoped></style>
